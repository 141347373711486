import React from 'react';
import * as styles from './regionen.module.scss';
import Layout from "../page-components/layout/layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const RegionenPage = ( { data } ) => {

  const regions = data.allWpRegion.nodes;

  return <Layout>

    {data.wp.themeGeneralSettings.acf_settings.teaserregions?.localFile &&
      <GatsbyImage alt={"Teaser Image Orte"}
                   className={styles.teaser}
                   image={data.wp.themeGeneralSettings.acf_settings.teaserregions.localFile.childImageSharp.gatsbyImageData} />
    }

    <div className="py-4 bg-primary-dark text-white text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">

            <h1>Regionen</h1>

          </div>
        </div>
      </div>
    </div>


    <div className="container pt-3 pb-5">
      <div className="row">

        { regions
          .filter(
            r => !!r.regionAcf?.teaser
          )
          .map(
          k => <div className={"col-lg-4 mt-4"}>

            <Link to={"/region/" + k.slug} className={"text-decoration-none"}>
              <div className={styles.card}>

                <GatsbyImage alt={"Kategorie " + k.title}
                             className={styles.cardImage}
                             image={k.regionAcf?.teaser?.localFile?.childImageSharp?.gatsbyImageData} />

                <div className="text-center pt-2 mt-2 text-white">
                  <p>{ k.title }</p>
                </div>

              </div>
            </Link>

          </div>
        )}


      </div>
    </div>

  </Layout>

};

export default RegionenPage;


export const query = graphql`
query RegionenListPage {

    wp {
      themeGeneralSettings {
        acf_settings {
          teaserregions {
            localFile {
              childImageSharp {
                gatsbyImageData(layout:FULL_WIDTH)
              }
            }
          }
        }
      }
    }


  allWpRegion {
    nodes {
      id
      slug
      title
      regionAcf {
        teaser {
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 2.5)
            }
          }
        }
      }
    }
  }
}  
`;
